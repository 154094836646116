import { RequestInterfaceNew, GetRequestData } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'
import { TicketInterface, TicketFullInterface } from '@src/interfaces/tickets'
import { FilterByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const ticketsRequests: RequestInterfaceNew<TicketInterface> = {
  get: async ({ id }) => api.get(`${API.TICKETS}/${id}`),
  submit: async data => apiWithoutHandling.post(`${API.TICKETS}`, data),
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.TICKETS}/${id}`, data),
}

export const useGetTickets = (filtersParams: FilterByInterface[]) =>
  useFetch<GetRequestData<TicketFullInterface>>(API.TICKETS, 'v1', {
    params: filterSortPageIntoQuery(undefined, filtersParams),
  })

export const useGetTicket = (id?: string) => {
  return useFetch<TicketFullInterface>(id ? `${API.TICKETS}/${id}` : null)
}

export const reopenTicket = (id: string | number) =>
  apiWithoutHandling.post(`${API.TICKETS}/${id}/reopen`)

export const closeTicket = (id: string | number) =>
  apiWithoutHandling.post(`${API.TICKETS}/${id}/close`)
